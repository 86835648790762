import React, { useEffect, useState } from "react";
import "./button.css";
import SocialShareModal from "./SocialShareModal";
import { getLinkPreview } from "link-preview-js";

const WebLink = ({ className, prefixIcon, postfixIcon, children, link, icon }) => {
  console.log(prefixIcon);
  const [showModal, setShowModal] = useState(false);
  const [previewLink1, setPreviewLink1] = useState("");

  const handleModalOpen = (e) => {
    e.preventDefault(); // Prevent the link from being followed
    e.stopPropagation();
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  console.log(previewLink1);

  useEffect(() => {
    console.log('step 1', link);
    getLinkPreview(link)
      .then((data) => {
        console.log("mydata->>>>>>",data);
        setPreviewLink1(
          data.images && data.images.length > 0
            ? data.images.find(function (element) {
                return (
                  element.includes(".png") ||
                  element.includes(".jpg") ||
                  element.includes(".jpeg") ||
                  element.includes(".ico")
                );
              })
            : undefined
        );
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  }, []);


  return (
    <div>
      <a
        className={`${className} flex items-center justify-center relative bg-white`}
        target="_blank"
        href={link}
      >
        {icon ? (
          <img 
          src={icon || previewLink1} 
          className="absolute prefix-icon w-8 h-8 rounded-full border-4 border-white shadow-lg"
        />
        
        ) : <span  className="absolute prefix-icon">{prefixIcon}</span>}
        {children}
        {postfixIcon && (
          <span className="absolute postfix-icon" onClick={handleModalOpen}>
            {postfixIcon}
          </span>
        )}
      </a>
      <SocialShareModal
        show={showModal}
        onClose={handleModalClose}
        link={link}
      />
    </div>
  );
};

export default WebLink;
